import React, { Component, useState, useEffect } from "react"
import { Link } from "gatsby"
import axios from "axios"
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Page from '../components/Page';
import { useSiteMetadata } from '../hooks';


const ClientFetchingExample = props => {

  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();



  const [books, setBooks] = useState({
    loading: true,
    response: null,
  });

  const [images, setImages] = useState({
    loadingImages: true,
    imageResponse: null,
  });

  useEffect(() => {
    // TODO
    
    readingProfile().then(data => {
      setBooks({
        loading: false,
        response: data,
      })
    });


  }, [])

  const { loading, response } = books;


  return (
    <Layout title={siteTitle} description={siteSubtitle}>
      <Sidebar isIndex />
      <Page>
      {/* {loading && 'Loading...'}
      {JSON.stringify(response.review, null, 4)} */}

      <h2 className="pageHeader">Books i'm reading</h2>
      
      {loading ? (
              <div>
              <p>Please hold, scraping Goodreads API!</p>
              <ReadingAnimation></ReadingAnimation>
              </div>
              
            ) : response.review ? (
              <div>
                {/* {JSON.stringify(response.review, null, 4)} */}
                
                {/* {console.log(response)} */}
                <h3 className="pageHeader">The {response.review.length} books currently on my shelf</h3>
                <span>From <a href="https://www.goodreads.com/" target="_blank">Goodreads</a></span>

                <div className="bookList">
                <ul>
                
                {
                response.review.map((el, index) => {
                  return (
                    
                      <li key={response.review[index].id}>
                      <a href={response.review[index].book.link} target="_blank">
                        <img src={response.review[index].book.image_url}></img>
                        <h3>{response.review[index].book.title}</h3>
                        <p>{response.review[index].book.description.substring(0,250)}...</p>
                      </a>
                      </li>
                    
                  )
                })
                }
                
                </ul>
                </div>
              </div>
            ) : (
              <p>Oh noes, error fetching pupper :(</p>
            )}


      
      </Page>
    </Layout>
  )
}

// https://bamiyk9hx6.execute-api.us-east-1.amazonaws.com/production/getmybooks
// https://run.mocky.io/v3/5a208263-25e8-45b4-832a-f7b1a9618987
// https://www.mocky.io/v2/5e232dac2f000054002226eb
const readingProfile = () => {
  return axios
  .get(`https://run.mocky.io/v3/5a208263-25e8-45b4-832a-f7b1a9618987`)
  .then(response => {
    // console.log(response)
    return response.data.GoodreadsResponse.reviews;
  })
  .catch(console.error)
};




function ReadingAnimation() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="51"
      display="block"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
      style={{ margin: "auto" }}
    >
      <path
        fill="#93dbe9"
        stroke="#689cc5"
        strokeWidth="3"
        d="M20 25h60v50H20z"
      ></path>
      <path
        fill="#93dbe9"
        stroke="#689cc5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M50 25h30v50H50"
      >
        <animate
          attributeName="d"
          begin="0s"
          dur="1s"
          keyTimes="0;0.5;0.501;1"
          repeatCount="indefinite"
          values="M50 25L80 25L80 75L50 75;M50 25L50 20L50 80L50 75;M50 25L80 25L80 75L50 75;M50 25L80 25L80 75L50 75"
        ></animate>
        <animate
          attributeName="opacity"
          begin="0s"
          dur="1s"
          keyTimes="0;0.5;0.5001;1"
          repeatCount="indefinite"
          values="1;1;0;0"
        ></animate>
      </path>
      <path
        fill="#93dbe9"
        stroke="#689cc5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M50 25l26.525-.58v51.16L50 75"
      >
        <animate
          attributeName="d"
          begin="-0.166s"
          dur="1s"
          keyTimes="0;0.5;0.501;1"
          repeatCount="indefinite"
          values="M50 25L80 25L80 75L50 75;M50 25L50 20L50 80L50 75;M50 25L80 25L80 75L50 75;M50 25L80 25L80 75L50 75"
        ></animate>
        <animate
          attributeName="opacity"
          begin="-0.166s"
          dur="1s"
          keyTimes="0;0.5;0.5001;1"
          repeatCount="indefinite"
          values="1;1;0;0"
        ></animate>
      </path>
      <path
        fill="#93dbe9"
        stroke="#689cc5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M50 25l16.685-2.22v54.44L50 75"
      >
        <animate
          attributeName="d"
          begin="-0.33s"
          dur="1s"
          keyTimes="0;0.5;0.501;1"
          repeatCount="indefinite"
          values="M50 25L80 25L80 75L50 75;M50 25L50 20L50 80L50 75;M50 25L80 25L80 75L50 75;M50 25L80 25L80 75L50 75"
        ></animate>
        <animate
          attributeName="opacity"
          begin="-0.33s"
          dur="1s"
          keyTimes="0;0.5;0.5001;1"
          repeatCount="indefinite"
          values="1;1;0;0"
        ></animate>
      </path>
      <path
        fill="#93dbe9"
        stroke="#689cc5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M50 25H20v50h30"
      >
        <animate
          attributeName="d"
          begin="-0.33s"
          dur="1s"
          keyTimes="0;0.499;0.5;1"
          repeatCount="indefinite"
          values="M50 25L20 25L20 75L50 75;M50 25L20 25L20 75L50 75;M50 25L50 20L50 80L50 75;M50 25L20 25L20 75L50 75"
        ></animate>
        <animate
          attributeName="opacity"
          begin="-0.33s"
          dur="1s"
          keyTimes="0;0.4999;0.5;1"
          repeatCount="indefinite"
          values="0;0;1;1"
        ></animate>
      </path>
      <path
        fill="#93dbe9"
        stroke="#689cc5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M50 25H20v50h30"
      >
        <animate
          attributeName="d"
          begin="-0.166s"
          dur="1s"
          keyTimes="0;0.499;0.5;1"
          repeatCount="indefinite"
          values="M50 25L20 25L20 75L50 75;M50 25L20 25L20 75L50 75;M50 25L50 20L50 80L50 75;M50 25L20 25L20 75L50 75"
        ></animate>
        <animate
          attributeName="opacity"
          begin="-0.166s"
          dur="1s"
          keyTimes="0;0.4999;0.5;1"
          repeatCount="indefinite"
          values="0;0;1;1"
        ></animate>
      </path>
      <path
        fill="#93dbe9"
        stroke="#689cc5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M50 25l-23.515-1.08v52.16L50 75"
      >
        <animate
          attributeName="d"
          begin="0s"
          dur="1s"
          keyTimes="0;0.499;0.5;1"
          repeatCount="indefinite"
          values="M50 25L20 25L20 75L50 75;M50 25L20 25L20 75L50 75;M50 25L50 20L50 80L50 75;M50 25L20 25L20 75L50 75"
        ></animate>
        <animate
          attributeName="opacity"
          begin="0s"
          dur="1s"
          keyTimes="0;0.4999;0.5;1"
          repeatCount="indefinite"
          values="0;0;1;1"
        ></animate>
      </path>
    </svg>
  );
}


function Heart() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      display="block"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
      style={{ margin: "auto", background: "#fff", display: "inline" }}
    >
      <g transform="translate(50 62) scale(2)">
      <path
        fill="#e90c59"
        d="M27.745-23.382a17.034 17.034 0 00-24.132 0L0-19.77l-3.613-3.613a17.034 17.034 0 00-24.132 0 17.034 17.034 0 000 24.132l3.613 3.613 8.453 8.385L0 28.427l15.68-15.68 8.452-8.452L27.745.682c6.613-6.681 6.613-17.452 0-24.064z"
      >
        <animateTransform
          attributeName="transform"
          calcMode="spline"
          dur="1s"
          keySplines="0.215 0.61,0.355 1;0.215 0.61,0.355 1;0.215 0.61,0.355 1;0.215 0.61,0.355 1;0.215 0.61,0.355 1"
          keyTimes="0;0.05;0.39;0.45;0.6;1"
          repeatCount="indefinite"
          type="scale"
          values="0.68;0.8;0.6000000000000001;0.7200000000000001;0.68;0.6400000000000001"
        ></animateTransform>
      </path>
      </g>
    </svg>
  );
}

export default ClientFetchingExample